module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-RBM4TC7QEM"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ChordsMaster","short_name":"ChordsMaster","start_url":"/","display":"standalone","icon":"src/images/favicon.png","theme_color":"#FFFCF5","background_color":"#23273C","lang":"en","description":"Discover a vast collection of music chords with ChordsMaster. Access chords for thousands of songs and artists, save your favorites, and enjoy offline access. Customize your instrumental and musical configurations effortlessly. Perfect for musicians of all levels!","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"772a6b4538b4253d541bffbf4dfe28f0"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
